import React from "react";

import {
  Container,
  Box,
  FormControl,
  Input,
  FormGroup,
  FormLabel,
  TableHead,
  Table,
  TableRow,
  FormHelperText,
  TableCell,
  Button,
  InputLabel,
  MenuItem,
  Typography,
  Modal,
  // Customizable Area Start
  // Customizable Area End
  Select,
} from "@mui/material";

import OfflineBrowsingController, {
  Props,
  configJSON,
} from "./OfflineBrowsingController";

// Customizable Area Start
import { OfflineBrowsingProvider } from "./OfflineBrowsingProvider";
import OfflineBrowsingContext from "./OfflineBrowsingContext";
// Customizable Area End

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#afe9ed",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class OfflineBrowsing extends OfflineBrowsingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"md"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Typography variant="subtitle1" component="div">
                {configJSON.labelBodyText}
              </Typography>
              <OfflineBrowsingProvider
                navigation={this.props.navigation}
                id="OfflineBrowsingProvider"
              >
                <OfflineBrowsingContext.Consumer>
                  {(offlineBrowsingContext) => (
                    <>
                      <Button
                        data-test-id="sendNetworkRequestButton"
                        title="Send Network Request"
                        onClick={() =>
                          this.sendNetworkRequest(offlineBrowsingContext)
                        }
                        style={webStyle.buttonStyle}
                      >
                        Send Network Request
                      </Button>
                      <Button
                        data-test-id="saveNetworkSafeDataButton"
                        title="Save Dummy Data Offline"
                        onClick={() =>
                          this.saveDataOffline(offlineBrowsingContext, {
                            someData: offlineBrowsingContext.offlineState,
                          })
                        }
                        style={webStyle.buttonStyle}
                      >
                        Save Data Offline
                      </Button>
                      <Button
                        data-test-id="displayNetworkSafeDataButton"
                        title="Display Saved Data"
                        onClick={() =>
                          this.getSavedData(offlineBrowsingContext)
                        }
                        style={webStyle.buttonStyle}
                      >
                        Display Saved Data
                      </Button>
                      {offlineBrowsingContext.isConnected
                        ? "Online"
                        : "You are offline"}

                      {this.state.exampleData
                        ? JSON.stringify(this.state.exampleData)
                        : null}
                    </>
                  )}
                </OfflineBrowsingContext.Consumer>
              </OfflineBrowsingProvider>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    // display: 'flex',
    fontFamily: "Roboto-Medium",
    // flexDirection: 'column',
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColo: "#b3d3e6",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
