import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
// Customizable Area End

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import { HexColorPicker } from "react-colorful";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ThemeBlockController, {
  Props,
  configJSON,
} from "./ThemeBlockController";

export default class ThemeBlock extends ThemeBlockController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const fontWeight = this.state.theme?.isBold ? "bold" : "normal";

    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"xl"}>
            <div style={{ display: "flex" }}>
              <div>
                <HexColorPicker
                  data-test-id={"bgColorPicker"}
                  color={this.state.theme.backgroundColor}
                  onChange={(colors: string) => this.setColor(colors)}
                />
                <Typography
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.chooseBackgroundColor}
                </Typography>
              </div>
              <div style={{ marginLeft: 20 }}>
                <HexColorPicker
                  data-test-id={"textColorPicker"}
                  color={this.state.theme.textColor}
                  onChange={(colors: string) => this.setTextColor(colors)}
                />
                <Typography
                  data-test-id="textColorTitle"
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.chooseTextColor}
                </Typography>
              </div>
              <div style={{ marginLeft: 20 }}>
                <HexColorPicker
                  data-test-id={"textBgColorPicker"}
                  color={this.state.theme.textColor}
                  onChange={(colors: string) =>
                    this.setBackgroundTextColor(colors)
                  }
                />
                <Typography
                  data-test-id="textBackgroundColorTitle"
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.chooseTextBackgroundColor}
                </Typography>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  data-test-id="boldModeSwitch"
                  checked={this.state.theme.isBold}
                  color={
                    this.state.theme.textColor === "default"
                      ? "default"
                      : this.state.theme.textColor === "primay"
                      ? "primary"
                      : this.state.theme.textColor === "secondary"
                      ? "secondary"
                      : undefined
                  }
                  onChange={() => this.setBoldType()}
                />
                <Typography
                  data-test-id={"isBoldTitle"}
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.isBoldLabel}
                </Typography>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  data-test-id="italicModeSwitch"
                  checked={this.state.theme.fontType === "italic"}
                  color={
                    this.state.theme.textColor === "default"
                      ? "default"
                      : this.state.theme.textColor === "primay"
                      ? "primary"
                      : this.state.theme.textColor === "secondary"
                      ? "secondary"
                      : undefined
                  }
                  onChange={() => this.toggleItalic()}
                />
                <Typography
                  data-test-id="isItalicTitle"
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.isItalicLabel}
                </Typography>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  data-test-id={"underLineModeSwitch"}
                  checked={this.state.theme.underlined}
                  color={
                    this.state.theme.textColor === "default"
                      ? "default"
                      : this.state.theme.textColor === "primay"
                      ? "primary"
                      : this.state.theme.textColor === "secondary"
                      ? "secondary"
                      : undefined
                  }
                  onChange={() => this.setUnderlined()}
                />
                <Typography
                  data-test-id="isUnderlineTitle"
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                  }}
                  variant="h6"
                >
                  {configJSON.isUnderlinedLabel}
                </Typography>
              </div>
            </div>
            <Box
              data-test-id="labelsContainer"
              sx={{
                display: "flex",
                fontFamily: "Roboto-Medium",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "30px",
                bgcolor: this.state.theme.backgroundColor,
              }}
            >
              <Typography
                style={{
                  color: this.state.theme.textColor,
                  fontWeight,
                  fontStyle: this.state.theme.fontType,
                  textDecoration: this.state.theme.underlined
                    ? "underline 3px"
                    : undefined,
                  backgroundColor: this.state.theme.textBackgroundColor,
                }}
                variant="h6"
              >
                {configJSON.labelTitleText}
              </Typography>
              <Typography
                data-test-id="labelBody"
                style={{
                  color: this.state.theme.textColor,
                  fontWeight,
                  fontStyle: this.state.theme.fontType,
                  textDecoration: this.state.theme.underlined
                    ? "underline 3px"
                    : undefined,
                  backgroundColor: this.state.theme.textBackgroundColor,
                }}
                variant="subtitle1"
                component="div"
              >
                {configJSON.labelBodyText}
              </Typography>
              <Box sx={webStyle.inputStyle}>
                <InputLabel
                  style={{
                    color: this.state.theme.textColor,
                    fontWeight,
                    fontStyle: this.state.theme.fontType,
                    textDecoration: this.state.theme.underlined
                      ? "underline 3px"
                      : undefined,
                    backgroundColor: this.state.theme.textBackgroundColor,
                    width: "110px",
                  }}
                  id="service-shop-name"
                >
                  {configJSON.thisIsTextLabel}
                </InputLabel>
              </Box>
              <Button
                data-test-id={"resetBtn"}
                variant="outlined"
                style={webStyle.buttonStyle}
                onClick={this.resetTheme}
              >
                {configJSON.resetButton}
              </Button>
              <Button
                data-test-id={"updateBtn"}
                variant="outlined"
                style={webStyle.buttonStyle}
                onClick={() => this.updateTheme(this.state.themeId)}
              >
                {configJSON.updateButton}
              </Button>
              <Button
                data-test-id={"deleteBtn"}
                variant="outlined"
                style={webStyle.buttonStyle}
                onClick={() => this.deleteTheme(this.state.themeId)}
              >
                {configJSON.deleteButton}
              </Button>

              <Button
                data-test-id={"getAllThemesBtn"}
                variant="outlined"
                style={webStyle.buttonStyle}
                onClick={() => this.getAllThemes()}
              >
                {configJSON.getAllThemesBtn}
              </Button>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "50%",
    height: "30px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  borderColor: {
    width: "100%",
    height: "45px",
    borderColor: "blue",
  },
};
// Customizable Area End
